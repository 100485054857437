import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ScenarioDetailType } from 'prosumer-app/stores';
import {
  TimePeriodInfo,
  TimePeriodStore,
} from 'prosumer-app/stores/time-periods';

@Injectable({
  providedIn: 'root',
})
export class TimePeriodInfoService {
  constructor(public readonly store: TimePeriodStore) {}

  getTimePeriods(): Observable<unknown> {
    return this.store.improvedGetAll();
  }

  createTimePeriod(data: TimePeriodInfo): Observable<unknown> {
    return this.store.createTimePeriod({
      ...data,
      dataType: ScenarioDetailType.timePeriod,
    });
  }

  updateTimePeriod(data: TimePeriodInfo): Observable<unknown> {
    return this.store.updateTimePeriod({ ...data });
  }

  deleteTimePeriod(id: string): Observable<unknown> {
    return this.store.deleteTimePeriod(id);
  }

  removeTimePeriodsFromStore(ids: string[]): void {
    ids.map((id) => {
      this.store.remove(id);
    });
  }
}
