import { Coerce } from 'prosumer-app/core/utils';
import {
  ApiRequest,
  ApiRequestType,
  StoreApiService,
} from 'prosumer-app/services/store-api';
import { Observable, of, throwError } from 'rxjs';
import { catchError, finalize, map, tap } from 'rxjs/operators';

import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EntityStore } from '@datorama/akita';

import {
  DetailEntity,
  HasScenarioUuid,
  Params,
  ScenarioDetailState,
  ScenarioDetailStatus,
  ScenarioDetailType,
} from './scenario-detail.state';
import { Upserter } from './scenario-detail.tokens';

/**
 * Note (StoreConfig):
 * name => also used as dataType
 */
@Injectable({ providedIn: 'root' })
export abstract class ScenarioDetailStore<T extends DetailEntity>
  extends EntityStore<ScenarioDetailState<T>>
  implements Upserter<T>
{
  constructor(private apiService: StoreApiService) {
    super();
    this._setState(this.initializeState());
  }

  deleteOne(id: string): Observable<unknown> {
    this.upsertLoading(id, true);
    return this.delete({
      key: 'deleteDetail',
      data: { id },
      body: undefined,
    }).pipe(
      tap(() => this.remove(id)),
      catchError((err) => this.onDeleteErr(id, err)),
    );
  }

  private onDeleteErr(id: string, err: HttpErrorResponse): Observable<unknown> {
    this.upsertLoading(id, false);
    return throwError(() => err.error.error);
  }

  create(data: T): Observable<unknown> {
    this.setLoading(true);
    return this.post({
      key: 'createDetail',
      data: {},
      body: this.toBEWithDataType(data),
    }).pipe(
      map((res: unknown) => this.toFECommon(res as HasScenarioUuid)),
      tap((rsp: T) => this.upsert(this.getDataUuid(rsp) as string, rsp as T)),
      finalize(() => this.setLoading(false)),
    );
  }

  edit(id: string, data: T): Observable<unknown> {
    this.upsertLoading(id, true);
    return this.patch({
      key: 'updateDetail',
      data: { id },
      body: this.toBEWithDataType(data),
    }).pipe(
      map((res: unknown) => this.toFECommon(res as HasScenarioUuid)),
      tap((rsp: T) => this.upsert(this.getDataUuid(rsp) as string, rsp as T)),
      finalize(() => this.upsertLoading(id, false)),
    );
  }

  upsertLoading(id: string, loading: boolean): void {
    this.upsert(id, { ...this.getEntity(id), loading });
  }

  upsertStatus(id: string, status: ScenarioDetailStatus): void {
    this.upsert(id, { ...this.getEntity(id), status });
  }

  improvedGetAll(params: Params = {}): Observable<T[]> {
    return this.getAll(this.coerceStoreNameToDetailType(), params);
  }

  getAll(
    dataType: ScenarioDetailType | string,
    params: Params = {},
  ): Observable<T[]> {
    this.setLoading(true);
    return this.apiService.getAll(dataType, params).pipe(
      map((rsp) => rsp.details),
      map((list) => this.listToFEs(list)),
      tap((list) => this.set(list)),
      finalize(() => this.setLoading(false)),
      catchError((err) => this.onGetAllError(err)),
    ) as Observable<T[]>;
  }

  improvedGetSingle(id: string, params: Params = {}): Observable<T> {
    return this.getSingle(this.coerceStoreNameToDetailType(), id, params);
  }

  getSingle(
    dataType: string,
    id: string,
    params?: Record<string, string>,
  ): Observable<T> {
    this.upsertLoading(id, true);
    this.setActive(id);
    return this.apiService.getSingle(dataType, id, params).pipe(
      map((datum) => this.toFECommon(datum as HasScenarioUuid)),
      tap((datum) => this.upsert(id, datum)),
      finalize(() => this.upsertLoading(id, false)),
    ) as Observable<T>;
  }

  get(params: ApiRequest<T>): Observable<unknown> {
    return this.request(ApiRequestType.get, this.prepareParamData(params));
  }

  post(params: ApiRequest<T>): Observable<unknown> {
    return this.request(ApiRequestType.post, this.prepareParamData(params));
  }

  patch(params: ApiRequest<T>): Observable<unknown> {
    return this.request(ApiRequestType.patch, this.prepareParamData(params));
  }

  delete(params: ApiRequest<T>): Observable<unknown> {
    return this.request(ApiRequestType.delete, this.prepareParamData(params));
  }

  duplicate(id: string, body: unknown): Observable<unknown> {
    this.setLoading(true);
    return this.apiService.duplicate(this.storeName, id, body).pipe(
      map((datum) => this.toFECommon(datum as HasScenarioUuid)),
      tap((data) => this.upsert(id, data as T)),
      finalize(() => this.setLoading(false)),
    );
  }

  weirdPatch(
    request: ApiRequest<T>,
    params: Record<string, string> = {},
  ): Observable<unknown> {
    return this.apiService.weirdPatch(request, params);
  }

  toBE(from: T): unknown {
    return from;
  }

  toFE(from: unknown): T {
    return from as T;
  }

  getEntity(id: string): T {
    return Coerce.toObject(this.getValue().entities[id]);
  }

  private toFECommon(from: DetailEntity): T {
    return {
      ...from,
      ...this.toFE(from),
    };
  }

  protected toBEWithDataType(data: T): T {
    return { ...(this.toBE(data) as T), dataType: this.storeName };
  }

  protected initializeState(): ScenarioDetailState<T> {
    return {
      entities: {},
      ids: [],
      loading: false,
      error: null,
    };
  }

  protected onError(error: unknown): Observable<never> {
    this.setError(error);
    return throwError(() => error);
  }

  protected prepareParamData(params: ApiRequest<T>): ApiRequest<T> {
    return {
      key: params.key,
      data: this.getData(params),
      body: this.getBody(params),
    };
  }

  protected getDataUuid(data: Record<string, unknown>): unknown {
    return data['id'];
  }

  private listToFEs(from: unknown[]): T[] {
    return from.map((item) => this.toFECommon(item as HasScenarioUuid));
  }

  private getBody(params: ApiRequest<T>): T {
    return { dataType: this.storeName, ...params.body };
  }

  private getData(params: ApiRequest<T>): Record<string, unknown> {
    return {
      id: this.getDataUuid(params.data),
      dataType: this.storeName,
      ...params.data,
    };
  }

  private request(
    key: ApiRequestType,
    params: ApiRequest<T>,
  ): Observable<unknown> {
    return this.apiService[key](params).pipe(
      catchError((err: unknown) => this.onError(err)),
    );
  }

  private coerceStoreNameToDetailType(): ScenarioDetailType {
    return this.storeName as ScenarioDetailType;
  }

  private onGetAllError(error: HttpErrorResponse): Observable<unknown> {
    this.update({ getAllError: error?.error?.error });
    return of({});
  }
}
