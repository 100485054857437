/* eslint-disable deprecation/deprecation */
import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';

import {
  MessageActions,
  MessageActionTypes,
  messageFeatureName,
} from './scenario-message.actions';
import { MessageState } from './scenario-message.state';

/**
 * Initial state of simulation message store
 */
export const messageInitialState: MessageState = {
  error: undefined,
  loading: false,
  data: undefined,
  currentScenarioID: undefined,
};

/**
 * Reducer function that updates the state of the message store
 *
 * @param state = State of the scenario message store
 * @param action = Most recent dispatched action by the scenario message store
 */

export function messageReducer(
  state: MessageState = messageInitialState,
  action: MessageActions,
): MessageState {
  switch (action.type) {
    case MessageActionTypes.GET:
      return {
        ...state,
        messages: [],
        error: undefined,
        loading: true,
      };
    case MessageActionTypes.GET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case MessageActionTypes.GET_SUCCESS:
      return {
        ...state,
        loading: false,
        messages: action.payload.data.messages,
        version: action.payload.data.version || '0.0',
        currentScenarioID: action.payload.currentScenarioID,
      };
    case MessageActionTypes.GET_EXCEL_IMPORT:
      return {
        ...state,
        messages: [],
        error: undefined,
        loading: true,
      };
    case MessageActionTypes.GET_EXCEL_IMPORT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case MessageActionTypes.GET_EXCEL_IMPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        messages: action.payload.data.messages,
        version: action.payload.data.version || '0.0',
        currentScenarioID: action.payload.currentScenarioID,
        isImportMessage: action.payload.data.isImportMessage || false,
      };
    case MessageActionTypes.CLEAR:
      return {
        ...messageInitialState,
      };
    default:
      return state;
  }
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const messageFeature: MemoizedSelector<any, MessageState> =
  createFeatureSelector<MessageState>(messageFeatureName);

// eslint-disable-next-line @typescript-eslint/ban-types
export const messageSelectors: { [key: string]: MemoizedSelector<any, any> } = {
  state: messageFeature,
  loading: createSelector(
    messageFeature,
    (state: MessageState) => state.loading,
  ),
  data: createSelector(messageFeature, (state: MessageState) => state.data),
  messages: createSelector(
    messageFeature,
    (state: MessageState) => state.messages,
  ),
  version: createSelector(
    messageFeature,
    (state: MessageState) => state.version,
  ),
  error: createSelector(messageFeature, (state: MessageState) => state.error),
  currentScenarioID: createSelector(
    messageFeature,
    (state: MessageState) => state.currentScenarioID,
  ),
  isImportMessage: createSelector(
    messageFeature,
    (state: MessageState) => state.isImportMessage,
  ),
};
