import { ScenarioMapper } from 'prosumer-app/+scenario/services/mappers';
import { ConfigService } from 'prosumer-app/libs/eyes-core';
import { ActiveKeeperService } from 'prosumer-app/services/active-keeper';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import { parseTemplate } from 'url-template';

/* eslint-disable no-useless-escape */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EntityStore, StoreConfig } from '@datorama/akita';

import {
  initializeState,
  ScenarioGeneric,
  ScenarioGenericState,
} from './scenario-generic.state';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'scenarios', idKey: 'scenarioUuid' })
export class ScenarioGenericStore extends EntityStore<ScenarioGenericState> {
  constructor(
    private http: HttpClient,
    private _config: ConfigService,
    private readonly keeper: ActiveKeeperService,
  ) {
    super(initializeState());
  }

  getInfo(): Observable<unknown> {
    this.setLoading(true);
    return this.http.get(this.getEndpoint('get')).pipe(
      catchError((err: unknown) => this.onError(err)),
      finalize(() => this.setLoading(false)),
    );
  }

  getInfoWithKey(endpointKey: string, params: unknown): Observable<unknown> {
    this.setLoading(true);
    return this.http.get(this.getEndpointWithData(endpointKey, params)).pipe(
      catchError((err: unknown) => this.onError(err)),
      finalize(() => this.setLoading(false)),
    );
  }

  postInfo<T>(endpointKey: string, data: T): Observable<unknown> {
    this.setLoading(true);
    return this.http.post(this.getEndpoint(endpointKey), { ...data }).pipe(
      catchError((err: unknown) => this.onError(err)),
      finalize(() => this.setLoading(false)),
    );
  }

  deleteInfo<T>(endpointKey: string, data: T): Observable<unknown> {
    this.setLoading(true);
    return this.http.delete(this.getEndpointWithData(endpointKey, data)).pipe(
      catchError((err: unknown) => this.onError(err)),
      finalize(() => this.setLoading(false)),
    );
  }

  handleAdd($: Observable<unknown>): Observable<unknown> {
    return $.pipe(
      map((data: ScenarioGeneric) => this.initLimitsValues(data)),
      tap((data: ScenarioGeneric) => this.upsert(data.scenarioUuid, data)),
      tap((data: ScenarioGeneric) => this.setActive(data.scenarioUuid)),
    );
  }

  initLimitsValues(scenario: ScenarioGeneric): ScenarioGeneric {
    if (!scenario.limits) {
      return {
        ...scenario,
        limits: {
          yearlyMaximumEmissions: null,
          yearlyMaximumCosts: null,
        },
      };
    }
    return scenario;
  }

  handleUpdate($: Observable<unknown>): Observable<unknown> {
    return $.pipe(
      map((data: ScenarioGeneric) =>
        ScenarioMapper.mapYearlyValuesFromBE(data),
      ),
      tap((data: unknown) =>
        this.update(this.defaultedScenarioUuid(data), data),
      ),
    );
  }

  private defaultedScenarioUuid(data: unknown): string {
    return data['scenarioUuid'] ?? this.keeper.getActiveEntities().scenario;
  }

  getEndpoint(key: string): string {
    const data = this.keeper.getActiveEntities();
    return parseTemplate(this.getUrlTemplate(key)).expand(data as any);
  }

  getEndpointWithData(key: string, data: any): string {
    const expandData = { ...this.keeper.getActiveEntities(), ...data };
    return parseTemplate(this.getUrlTemplate(key)).expand({ ...expandData });
  }

  private getUrlTemplate(urlKey: string): string {
    const endpoint = this._config.api.endpoints['scenario'][urlKey];
    const cleanEndpoint = endpoint.replace(/\:(\w*)Id|\:(\w*)/g, '{$1$2}');
    return `${this._config.api.baseUrl}${cleanEndpoint}`;
  }

  private onError(error: unknown): Observable<unknown> {
    this.setError(error);
    return throwError(() => error);
  }
}
