/* eslint-disable deprecation/deprecation */
import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import {
  ExcelImportMessageGetAction,
  MessageClearAction,
  MessageGetAction,
} from './scenario-message.actions';
import { messageSelectors } from './scenario-message.reducer';
import { MessageState } from './scenario-message.state';

@Injectable()
export class MessageStore {
  state$: Observable<MessageState> = this._store$.pipe(
    select(messageSelectors.state),
  );
  loading$: Observable<boolean> = this._store$.pipe(
    select(messageSelectors.loading),
  );
  data$: Observable<any> = this._store$.pipe(select(messageSelectors.data));
  messages$: Observable<any> = this._store$.pipe(
    select(messageSelectors.messages),
  );
  version$: Observable<any> = this._store$.pipe(
    select(messageSelectors.version),
  );
  error$: Observable<any> = this._store$.pipe(select(messageSelectors.error));
  isImportMessage$: Observable<any> = this._store$.pipe(
    select(messageSelectors.isImportMessage),
  );

  constructor(private _store$: Store<MessageState>) {}

  /**
   * Fetch the messages for a specific scenario ID
   *
   * @param scenarioId ID of the Scenario you'd want to get the results from
   */
  getMessages(
    projectId: string,
    caseId: string,
    scenarioId: string,
  ): Observable<any> {
    this._store$.dispatch(new MessageGetAction(projectId, caseId, scenarioId));
    return this.messages$;
  }

  /**
   * Clear the messages!
   */
  clearMessages(): Observable<any> {
    this._store$.dispatch(new MessageClearAction());
    return this.messages$;
  }

  /**
   * Fetch the excel import messages/errors for a specific scenario ID
   *
   * @param projectId ID of the project you'd want to get the messages from
   * @param caseId ID of the case you'd want to get the messages from
   * @param scenarioId ID of the scenario you'd want to get the messages from
   */
  getExcelImportMessages(
    projectId: string,
    caseId: string,
    scenarioId: string,
  ): Observable<any> {
    this._store$.dispatch(
      new ExcelImportMessageGetAction(projectId, caseId, scenarioId),
    );
    return this.messages$;
  }
}
