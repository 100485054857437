/* eslint-disable deprecation/deprecation */
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { SCENARIO_COMPARE_COLOR_REF } from 'prosumer-app/app.references';
import { getKeys } from 'prosumer-app/libs/eyes-shared';
import {
  CompareCashFlowResultItem,
  CompareCashFlowResultItemValue,
  CompareDispatchResultItem,
  CompareEnergyBalanceResultItem,
  CompareEquipmentResultItem,
  CompareMainResult,
  ComparePaybackResult,
  CompareTopologyResultItem,
  Result,
  ScenarioResult,
} from 'prosumer-scenario/models';

import { ScenarioCompareState } from './scenario-compare-state.model';
import { scenarioCompareFeature } from './scenario-compare.actions';

export const assignColorReference = (
  resultList: Array<Result>,
  colorRef: { [key: string]: string } = SCENARIO_COMPARE_COLOR_REF,
) => {
  if (!!!resultList || !!!colorRef) {
    return resultList || [];
  }

  const colorKeys = getKeys(colorRef);
  if (resultList.length > colorKeys.length) {
    return resultList || [];
  }

  return resultList.map((result, index) => ({
    ...result,
    colorClass: colorKeys[index],
  }));
};

/* Base Selectors */
export const scenarioCompareFeatureSelector =
  createFeatureSelector<ScenarioCompareState>(scenarioCompareFeature);
export const selectLoaded = createSelector(
  scenarioCompareFeatureSelector,
  (state: ScenarioCompareState) => state.loaded,
);
export const selectLoading = createSelector(
  scenarioCompareFeatureSelector,
  (state: ScenarioCompareState) => state.loading,
);
export const selectError = createSelector(
  scenarioCompareFeatureSelector,
  (state: ScenarioCompareState) => state.error,
);
export const selectMessage = createSelector(
  scenarioCompareFeatureSelector,
  (state: ScenarioCompareState) => state.message,
);
export const selectReferenceId = createSelector(
  scenarioCompareFeatureSelector,
  (state: ScenarioCompareState) => state.referenceId,
);
export const selectScenarioList = createSelector(
  scenarioCompareFeatureSelector,
  (state: ScenarioCompareState) => assignColorReference(state.resultList),
);
export const selectResultList = createSelector(
  scenarioCompareFeatureSelector,
  (state: ScenarioCompareState) => state.scenarioResultList || [],
);
export const selectIsOutputSplit = createSelector(
  scenarioCompareFeatureSelector,
  (state: ScenarioCompareState) => state.isOutputSplit,
);

export const selectEquipmentsResult = createSelector(
  scenarioCompareFeatureSelector,
  (state: ScenarioCompareState) => state.equipmentsResult || {},
);

export const selectTopologiesResult = createSelector(
  scenarioCompareFeatureSelector,
  (state: ScenarioCompareState) => state.topologiesResult || {},
);

export const selectEmissionsResult = createSelector(
  scenarioCompareFeatureSelector,
  (state: ScenarioCompareState) => state.emissionsResult || {},
);

/* Derived Selectors */
export const selectReferenceScenario = createSelector(
  scenarioCompareFeatureSelector,
  (state: ScenarioCompareState) =>
    (state.scenarioResultList || []).find(
      (result) => result.id === state.referenceId,
    ),
);

export const selectNonReferenceScenarios = createSelector(
  scenarioCompareFeatureSelector,
  (state: ScenarioCompareState) =>
    (state.scenarioResultList || []).filter(
      (result) => result.id !== state.referenceId,
    ),
);

/*
 * MAIN RESULTS
 */
export const selectMainResultList = createSelector(
  selectResultList,
  (resultList: Array<ScenarioResult>) =>
    resultList.map(
      (result) =>
        ({
          ...result.mainResult,
          name: result.name,
          id: result.id,
        }) as CompareMainResult,
    ),
);

export const selectMainReferenceScenario = createSelector(
  selectReferenceScenario,
  (result: ScenarioResult) =>
    !!result
      ? ({
          ...result.mainResult,
          name: result.name,
          id: result.id,
          referenceId: result.id,
          totalDistributedCostPercentDiff: 0,
          capexFirstYearPercentDiff: 0,
          opexFirstYearPercentDiff: 0,
          co2EmmissionPercentDiff: 0,
        } as CompareMainResult)
      : null,
);

/*
 * EQUIPMENT RESULTS
 */
export const selectEquipmentResultList = createSelector(
  selectResultList,
  (results: Array<ScenarioResult>) =>
    results
      .map((result) =>
        result.equipmentResult?.equipments.map((equipment) => ({
          ...equipment,
          id: result.id,
          name: result.name,
        })),
      )
      .reduce<Array<CompareEquipmentResultItem>>(
        (prev, next) => prev.concat(next),
        [],
      ),
);

export const selectEquipmentNodeOptions = createSelector(
  selectEquipmentResultList,
  (results: Array<CompareEquipmentResultItem>) =>
    Array.from(
      new Set(
        results
          .filter((filteredResult) => !!filteredResult.node)
          .map((result) => result.node),
      ),
    ),
);

export const selectEquipmentTypeOptions = createSelector(
  selectEquipmentResultList,
  (results: Array<CompareEquipmentResultItem>) =>
    Array.from(
      new Set(
        results
          .filter((filteredResult) => !!filteredResult.type)
          .map((result) => result.type),
      ),
    ),
);

export const selectEquipmentEnergyVectorOptions = createSelector(
  selectEquipmentResultList,
  (results: Array<CompareEquipmentResultItem>) =>
    Array.from(
      new Set(
        results
          .filter((filteredResult) => !!filteredResult.inputEnergyVector)
          .map((result) => result.inputEnergyVector)
          .concat(
            results
              .filter((filteredResult) => !!filteredResult.inputEnergyVector)
              .map((result) => result.outputEnergyVector),
          ),
      ),
    ),
);

/**
 * CASHFLOW RESULTS
 */
export const selectCashFlowResultList = createSelector(
  selectResultList,
  (results: Array<ScenarioResult>) =>
    results
      .map((result) =>
        result.cashFlowResult.cashFlowList.map((cashFlowItem) => ({
          ...cashFlowItem,
          data: [
            cashFlowItem.data
              .filter((opexItem) => opexItem.type === 'opex')
              .reduce<CompareCashFlowResultItemValue>(
                (prev, next) => ({
                  type: 'opex',
                  id: result.id,
                  name: result.name,
                  value: prev.value + next.value,
                  referenceId: result.referenceId,
                }),
                {
                  type: 'opex',
                  id: null,
                  name: null,
                  value: 0,
                  referenceId: null,
                },
              ),
            cashFlowItem.data
              .filter((opexItem) => opexItem.type === 'capex')
              .reduce<CompareCashFlowResultItemValue>(
                (prev, next) => ({
                  type: 'capex',
                  id: result.id,
                  name: result.name,
                  value: prev.value + next.value,
                  referenceId: result.referenceId,
                }),
                {
                  type: 'capex',
                  id: null,
                  name: null,
                  value: 0,
                  referenceId: null,
                },
              ),
          ],
        })),
      )
      .reduce<Array<CompareCashFlowResultItem>>(
        (prev, next) => prev.concat(next),
        [],
      ),
);

/* PAYBACK RESULTS */
export const selectPaybackResultList = createSelector(
  selectResultList,
  (results: Array<ScenarioResult>) =>
    results
      .map((result) => ({
        series: result.paybackResult.series,
        id: result.id,
        name: result.name,
        referenceId: result.referenceId,
        variationId: result.variationId,
      }))
      .reduce<Array<ComparePaybackResult>>(
        (prev, next) => prev.concat(next),
        [],
      ),
);

/**
 *  TOPOLOGY RESULTS
 */
export const selectTopologyResultList = createSelector(
  selectResultList,
  (results: Array<ScenarioResult>) =>
    results
      .map((result) =>
        result.topologyResult.topologies.map((topology) => ({
          ...topology,
          id: result.id,
          name: result.name,
        })),
      )
      .reduce<Array<CompareTopologyResultItem>>(
        (prev, next) => prev.concat(next),
        [],
      ),
);

export const selectTopologyNodeOptions = createSelector(
  selectTopologyResultList,
  (results: Array<CompareTopologyResultItem>) =>
    Array.from(
      new Set(
        results
          .filter((filteredResult) => !!filteredResult.originNode)
          .map((result) => result.originNode),
      ),
    ),
);

export const selectTopologyEnergyVectorOptions = createSelector(
  selectTopologyResultList,
  (results: Array<CompareTopologyResultItem>) =>
    Array.from(
      new Set(
        results
          .filter((filteredResult) => !!filteredResult.energyVector)
          .map((result) => result.energyVector),
      ),
    ),
);

export const selectTopologyDestinationNdeOptions = createSelector(
  selectTopologyResultList,
  (results: Array<CompareTopologyResultItem>) =>
    Array.from(
      new Set(
        results
          .filter((filteredResult) => !!filteredResult.destinationNode)
          .map((result) => result.destinationNode),
      ),
    ),
);

export const selectTopologyDestinationNodeOptions = createSelector(
  selectTopologyResultList,
  (results: Array<CompareTopologyResultItem>) =>
    Array.from(
      new Set(
        results
          .filter((filteredResult) => !!filteredResult.destinationNode)
          .map((result) => result.destinationNode),
      ),
    ),
);

/*
 * ENERGY BALANCE RESULTS
 */
export const selectEnergyBalanceResultList = createSelector(
  selectResultList,
  (results: Array<ScenarioResult>) =>
    results
      .map((result) =>
        result.energyBalanceResult.equipments.map((equipment) => ({
          ...equipment,
          id: result.id,
          name: result.name,
        })),
      )
      .reduce<Array<CompareEnergyBalanceResultItem>>(
        (prev, next) => prev.concat(next),
        [],
      ),
);

export const selectEnergyBalanceNodeOptions = createSelector(
  selectEnergyBalanceResultList,
  (results: Array<CompareEnergyBalanceResultItem>) =>
    Array.from(
      new Set(
        results
          .filter((filteredResult) => !!filteredResult.node)
          .map((result) => result.node),
      ),
    ),
);

export const selectEnergyBalanceYearOptions = createSelector(
  selectEnergyBalanceResultList,
  (results: Array<CompareEnergyBalanceResultItem>) =>
    Array.from(
      new Set(
        results
          .filter((filteredResult) => !!filteredResult.year)
          .map((result) => result.year),
      ),
    ),
);

export const selectEnergyBalanceEnergyVectorOptions = createSelector(
  selectEnergyBalanceResultList,
  (results: Array<CompareEnergyBalanceResultItem>) =>
    Array.from(
      new Set(
        results
          .filter((filteredResult) => !!filteredResult.energyVector)
          .map((result) => result.energyVector),
      ),
    ),
);

/* DISPATCH RESULTS */
export const selectDispatchResultList = createSelector(
  selectResultList,
  (results: Array<ScenarioResult>) =>
    results
      .map((result) =>
        result.dispatchResult?.dispatchList?.map((dispatch) => ({
          ...dispatch,
          id: result.id,
          name: result.name,
        })),
      )
      .reduce<Array<CompareDispatchResultItem>>(
        (prev, next) => prev.concat(next),
        [],
      ),
);

export const selectDispatchTypeOptions = createSelector(
  scenarioCompareFeatureSelector,
  (state: ScenarioCompareState) => state.dispatchTypes,
);

export const selectDispatchYearOptions = createSelector(
  selectDispatchResultList,
  (results: Array<CompareDispatchResultItem>) =>
    Array.from(
      new Set(
        results
          .filter((filteredResult) => !!filteredResult?.year)
          .map((result) => result.year)
          .sort(),
      ),
    ),
);

export const selectDispatchNodeOptions = createSelector(
  selectDispatchResultList,
  (results: Array<CompareDispatchResultItem>) =>
    Array.from(
      new Set(
        results
          .filter((filteredResult) => !!filteredResult?.node)
          .map((result) => result.node)
          .sort(),
      ),
    ),
);

export const selectDispatchEnergyVectorOptions = createSelector(
  selectDispatchResultList,
  (results: Array<CompareDispatchResultItem>) =>
    Array.from(
      new Set(
        results
          .filter((filteredResult) => !!filteredResult?.energyVector)
          .map((result) => result.energyVector)
          .sort(),
      ),
    ),
);

export const selectDispatchResultTypeOptions = createSelector(
  selectDispatchResultList,
  (results: Array<CompareDispatchResultItem>) =>
    Array.from(
      new Set(
        results
          // Don't inclued soc
          .filter(
            (filteredResult) =>
              !!filteredResult?.resultType &&
              filteredResult?.resultType !== 'soc',
          )
          .map((result) => result.resultType),
      ),
    ),
);

/* QUERIES */
export const scenarioCompareQueries = {
  /* Base */
  selectState: scenarioCompareFeatureSelector,
  selectLoaded,
  selectLoading,
  selectError,
  selectMessage,
  selectReferenceId,
  selectScenarioList,
  selectResultList,
  selectIsOutputSplit,
  selectEquipmentsResult,
  selectTopologiesResult,
  selectEmissionsResult,
  /* Derived */
  selectReferenceScenario,
  selectNonReferenceScenarios,
  /* Specific Results */
  // Main
  selectMainResultList,
  selectMainReferenceScenario,
  // Equipment
  selectEquipmentResultList,
  selectEquipmentNodeOptions,
  selectEquipmentTypeOptions,
  selectEquipmentEnergyVectorOptions,
  // cashFlow
  selectCashFlowResultList,
  // Energy Balance
  selectEnergyBalanceResultList,
  selectEnergyBalanceEnergyVectorOptions,
  selectEnergyBalanceNodeOptions,
  selectEnergyBalanceYearOptions,
  // Topology
  selectTopologyResultList,
  selectTopologyNodeOptions,
  selectTopologyEnergyVectorOptions,
  selectTopologyDestinationNdeOptions,
  selectTopologyDestinationNodeOptions,
  // Payback
  selectPaybackResultList,
  // Dispatch
  selectDispatchResultList,
  selectDispatchTypeOptions,
  selectDispatchYearOptions,
  selectDispatchNodeOptions,
  selectDispatchEnergyVectorOptions,
  selectDispatchResultTypeOptions,
};
