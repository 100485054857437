import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { ActiveKeeperService } from 'prosumer-app/services/active-keeper';
import { map, switchMap } from 'rxjs/operators';
import { ScenarioDetailState } from '../scenario-detail';
import { TimePeriodInfo } from './time-period.state';
import { TimePeriodStore } from './time-period.store';

@Injectable({ providedIn: 'root' })
export class TimePeriodQuery extends QueryEntity<
  ScenarioDetailState<TimePeriodInfo>
> {
  constructor(
    public store: TimePeriodStore,
    private readonly keeper: ActiveKeeperService,
  ) {
    super(store);
  }

  timePeriods$ = this.keeper.selectActive().pipe(
    switchMap(({ scenario }) =>
      this.selectAll({
        filterBy: (entity) => entity.scenarioUuid === scenario,
      }),
    ),
    map((periods) => this.insertPlaceholderNames(periods)),
  );

  allTimePeriod$ = this.selectAll();

  private insertPlaceholderNames(periods: TimePeriodInfo[]): TimePeriodInfo[] {
    return periods.map((period, idx) => ({
      ...period,
      name: `Period ${idx + 1}`,
    }));
  }
}
