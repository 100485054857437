/* eslint-disable deprecation/deprecation */
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { BillingState } from './billing-state.model';
import { billingFeature } from './billing.feature';

const featureState = createFeatureSelector<BillingState>(billingFeature);
const getComputingTime = createSelector(
  featureState,
  (state: BillingState) => state.computingTime,
);
const getStorage = createSelector(
  featureState,
  (state: BillingState) => state.storage,
);

export const billingQueries = {
  state: featureState,
  computingTime: getComputingTime,
  storage: getStorage,
};
