import {
  DetailCommentParams,
  EndpointProvider,
} from 'prosumer-app/services/endpoint-provider';
import {
  GeneralCommentsParams,
  GetCommentsResource,
} from 'prosumer-scenario/models/comment.types';
import {
  BehaviorSubject,
  catchError,
  finalize,
  map,
  Observable,
  of,
  pipe,
  startWith,
  switchMap,
  tap,
} from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Resource } from 'prosumer-app/shared/models/resource.types';

@Injectable({
  providedIn: 'root',
})
export class CommentService {
  private http = inject(HttpClient);
  private endpoints = inject(EndpointProvider);
  readonly refreshSeed = new BehaviorSubject<number>(0);

  getGeneralComments(
    params: GeneralCommentsParams,
  ): Observable<GetCommentsResource> {
    return this.refreshable(
      this.http
        .get<GetCommentsResource>(this.buildCommentsUrl(params))
        .pipe(this.resourcePipe()),
    ) as Observable<GetCommentsResource>;
  }

  editGeneralComment(
    params: GeneralCommentsParams,
    content: string,
  ): Observable<Resource> {
    return this.http
      .put<Resource>(this.buildCommentUrl(params), {
        content,
      })
      .pipe(
        tap(() => this.triggerRefresh()),
        this.resourcePipe(),
      );
  }

  createGeneralComment(
    params: GeneralCommentsParams,
    content: string,
  ): Observable<Resource> {
    return this.http
      .post<Resource>(this.buildCommentsUrl(params), {
        content,
      })
      .pipe(
        tap(() => this.triggerRefresh()),
        this.resourcePipe(),
      );
  }

  deleteGeneralComment(params: GeneralCommentsParams): Observable<Resource> {
    return this.http.delete<Resource>(this.buildCommentUrl(params)).pipe(
      finalize(() => this.triggerRefresh()),
      this.resourcePipe(),
    );
  }

  private triggerRefresh() {
    this.refreshSeed.next(Math.random());
  }

  private refreshable<T>($: Observable<T>): Observable<T> {
    return this.refreshSeed.pipe(switchMap(() => $));
  }

  private resourcePipe() {
    return pipe(
      map((rsp) => ({ data: rsp, loading: false })),
      startWith({ loading: true }),
      catchError(() => of({ loading: false, error: 'yikes' })),
    );
  }

  buildCommentUrl(params: GeneralCommentsParams): string {
    return this.isCommentForDetail(params)
      ? this.buildDetailSingleUrl(params)
      : this.buildGeneralSingleUrl(params);
  }

  buildCommentsUrl(params: GeneralCommentsParams): string {
    return this.isCommentForDetail(params)
      ? this.buildDetailUrl(params)
      : this.buildGeneralUrl(params);
  }

  private isCommentForDetail(params: GeneralCommentsParams): boolean {
    return [params.detailType, params.detailType].every(Boolean);
  }

  private buildGeneralUrl(params: GeneralCommentsParams): string {
    return this.endpoints.forGeneralComments({
      project: params.projectId,
      case: params.caseId,
      scenario: params.scenarioId,
    });
  }

  private buildGeneralSingleUrl(params: GeneralCommentsParams): string {
    return this.endpoints.forGeneralComment(
      {
        project: params.projectId,
        case: params.caseId,
        scenario: params.scenarioId,
      },
      params.commentId,
    );
  }

  private buildDetailSingleUrl(params: GeneralCommentsParams): string {
    return this.endpoints.forDetailComment(
      params as DetailCommentParams,
      params.commentId,
    );
  }

  private buildDetailUrl(params: GeneralCommentsParams): string {
    return this.endpoints.forDetailComments(params as DetailCommentParams);
  }
}
